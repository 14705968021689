// Step 1: Import React
import * as React from 'react'
import GlobalLayout from "../layout/global";
import {EuToucaImg, MainPresentation} from "../components/pages/home.styles";
import Fade from "react-reveal/Fade";
import Tooltip from "../components/Tooltip";
import Flip from "react-reveal/Flip";
import {AboutMe, AboutP} from "../components/pages/about.styles";

// Step 2: Define your component
const AboutPage = ({location}) => {
    return (
        <GlobalLayout pageTitle={"Sobre Kevin Santana"} location={location}>
            <EuToucaImg/>
            <MainPresentation>
                <Fade top opposite>
                    <AboutMe>Quem sou eu?</AboutMe>
                    <AboutP>29 anos, Formando na Instituição de Ensino IFBAIANO - Instituto Federal de Educação, Ciência
                        e Tecnologia Baiano, Catu-Ba a nível Tecnólogo, com experiência de mais 12 anos como
                        desenvolvedor back-end, com expertise em PHP e Javascript, trabalha há mais de 4 anos em
                        projetos baseado em Node.JS, GraphQL, e noSQL como FullStack, trabalhou como analista de sistema
                        no desenvolvimento de um projeto de sistema de cotação e compra auto-gerenciável para empresa
                        Perbras, desenvolver de projeto para corretora de seguros de saúde Ascents Company, gerente de
                        projeto em ERP para gerência de agronegocio e controle de fazendas. Atualmente é Desenvolvedor
                        de aplicações SaaS para empresas privadas e terceirizados e gerencia um projeto cultural da
                        Prefeitura Municipal de Catu.
                    </AboutP>
                </Fade>
                <Fade left opposite cascade>
                    <AboutMe>Como tudo começou?</AboutMe>
                    <AboutP>Me interessei por programação e desenvolvimento desde os 14 anos,
                        quando comecei estudando PHP em um tutorial fantástico feito em <Tooltip
                            tooltip={"Antigo método de reprodução de animações a partir de gráficos vetoriais criado pela FutureWave. Hoje em dia em desuso devido ao HTML5" +
                            " e suas tecnologias."}> Flash</Tooltip>,
                        o que me levou ainda mais a curtir a programação em geral.</AboutP>
                </Fade>
                <Fade right opposite cascade>
                    <AboutP>Mas a primeira motivação vei a partir dos jogos de computadores, na época, fanático
                        pelo <Tooltip
                            tooltip={`Counter-Strike v1.6. Jogo Eletrônico desenvolvido pela empresa Valve.`}>CS1.6</Tooltip> e
                        na onda
                        dos <Tooltip
                            tooltip={"Modificações com ou sem teor econômico criado pela comunidade para alterar algum ponto especifico de qualquer categoria de um determinado jogo. Normalmente é gratuito e criado por fãs que desejam realizar uma melhoria."}>MODS</Tooltip>,
                        tive uma ideia de melhorar os gráficos do jogo, e fiz uma série de modificações
                        utilizando pacotes de recursos baixados em sites de Moders, com o jogo baseado praticamente todo
                        em <Tooltip
                            tooltip={"Arquivo especial que guarda uma lista de imagens sequenciais semelhantes a um GIF, podendo ser manipulado via atributos para mostrar uma respectiva animação utilizando as imagens da lista."}>sprites</Tooltip>,
                        era fácil realizar alterações sem quebrar o funcionamento do jogo, a partir disso,
                        criei uma versão "1.7 (claro que não oficial), denominei de Nulk", foi febre nas LanHouses aqui
                        da cidade e alcançou mais de 10.000 downloads.</AboutP>
                </Fade>
                <Fade up>
                    <AboutP>A Vontade de programar realmente surgiu quando o desejo de evoluir ainda mais as
                        modificações no jogo chegou no ponto em que era necessário alterar valores no
                        código-fonte, coisa que para mim na época foi um muro intransponível, pois, eu não conhecia
                        nada do mundo da programação e desenvolvimento.</AboutP>
                </Fade>
                <Fade down>
                    <AboutP>Infelizmente como eu era muito leigo e inocente ao mesmo tempo na época, descobri mais tarde
                        que
                        existia Direitos Autorais e Processo judiciais que me arrepiou até a espinha, hahaha,
                        brincadeiras a parte,
                        após ter me informado que tais coisas existiam, a minha ação rápida foi extinguir quaisquer
                        vestígios do jogo modificado a até versionado.</AboutP>
                </Fade>
                <Flip top>
                    <AboutP>Depois dessa barbárie cometida, mesmo não tendo mais o jogo para modificar, a vontade de
                        aprender e me aprofundar no mundo da
                        programação só aumentou.</AboutP>
                    <AboutP>E aqui estamos nós, cristão, conservador, amante de jogos eletrônicos, de violão acústico,
                        de milkshake de brigadeiro (alá o nutella), trabalhando cerca de 12h por dia
                        para fazer da Web um lugar melhor... E ganhar um trocado também porque quem vive de brisa é
                        anemômetro, não é mesmo meu chapa, fala tu!!.</AboutP>

                </Flip>

            </MainPresentation>
        </GlobalLayout>
    )
}

// Step 3: Export your component
export default AboutPage
