import styled from "styled-components";

const AboutMe = styled.h1`
  color: #fff;
  text-shadow: 1px 2px 5px rgba(0,0,0,.22);
  padding-top: 20px;
`
const AboutP = styled.p`
  margin-bottom: 10px;
  max-width: 60vw;
  line-height: 1.8;
  text-indent: 1.5em;
  font-size: 14pt;
  text-transform: none;
  text-align: justify;
  
  @media(max-width: 500px){
    max-width: 76vw;
  }
`

export {
    AboutMe,
    AboutP
}
